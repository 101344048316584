<template>
    <div class="tolage">
        <Headerprestations :index="2" />
         <div class="titre">
            <h2>Protéger vos biens</h2>
            <p>Tôlage - Murage - Bardage</p>
            <small style="text-decoration: underline">ABSL La solution Global Service</small>
        </div>
        <div class="icon_container">
            <div class="icon" v-scroll-reveal="{ duration: 800, delay: 50, origin: 'bottom', distance: '50px'}">
                <img src="@/assets/voleur.png" alt="chrono">
                <p>Tôlage Résistant aux intrusions</p>
            </div>
            <div class="icon" v-scroll-reveal="{ duration: 800, delay: 150, origin: 'bottom', distance: '50px'}">
                <img src="@/assets/solution.png" alt="sans-fil">
                <p>Solutions adaptables à toutes situations </p>
            </div>
            <div class="icon" v-scroll-reveal="{ duration: 800, delay: 250, origin: 'bottom', distance: '50px'}">
                <img src="@/assets/sirene.png" alt="chrono">
                <p>Trappe de visite avec Alarme</p>
            </div>
        </div>
        <div class="images">
            <div class="AP">
                <img src="@/assets/tolage2.jpg" alt="tôlage" style="right: -40px;">
            </div>
            <div class="AP">
                <img src="@/assets/tolage1.jpg" alt="tôlage" style="right: -20px;">
            </div>
            <div class="AP">
                <img src="@/assets/trappe.jpg" alt="trappe">
            </div>
        </div>
        <div class="solution">
            <h2>ABSL une solution simple et efficace</h2>
            <ol>
                <li v-scroll-reveal="{ duration: 800, delay: 50, origin: 'bottom', distance: '50px'}">1- Présentez-nous simplement vos Besoins</li>
                <li v-scroll-reveal="{ duration: 800, delay: 150, origin: 'bottom', distance: '50px'}">2- Nous réalisons un diagnostic par téléphone, sur photo, ou surplace, nous vous proposons une Solution sur Mesure</li>
                <li v-scroll-reveal="{ duration: 800, delay: 250, origin: 'bottom', distance: '50px'}">3- Diagnostic avant 12h, nous installons le dispositif le jour J. Votre site est protégé avec ABSL</li>
                <li v-scroll-reveal="{ duration: 800, delay: 350, origin: 'bottom', distance: '50px'}">4- Nous adaptons la solution la plus efficiente, tôlage, murage, alarme. Nous vous transmettons le rapport d'activité régulièrement</li>
            </ol>
        </div>
        <p class="devise">La sécurité à toutes vos portes c'est ABSL</p>
        <div class="appel">
            <p v-scroll-reveal="{ duration: 800, delay: 250, origin: 'left', distance: '300px'}">Un projet de tôlage / murage ?</p>
            <router-link to="/contact" v-scroll-reveal="{ duration: 800, delay: 250, origin: 'right', distance: '300px'}">Contact</router-link>
        </div>
    </div>
</template>

<script>
import Headerprestations from '@/components/Headerprestations.vue'

export default {
    name: "tolage",
    components: {
        Headerprestations,
    }
}
</script>

<style scoped lang="scss">
    .tolage {
        overflow: hidden;
    }
    .titre {
        text-align: center;
        h2 {
            margin-top: 50px;
            text-transform: uppercase;
        }
        p {
            margin-top: 3px;
            color: #3c97f7;
            font-weight: bold;
        }
        small {
            font-weight: bold;
            margin-top: 10px;
        }
    }
  .icon_container {
      margin-top: 120px;
      margin-bottom: 150px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .icon {
          width: 20%;
          text-align: center;

          img {
              width: 55%;
          }
          p {
              font-size: 1.2rem;
              margin-top: 20px;
              text-align: center;
              font-weight: 900;
              color: #3c97f7;
          }
      }
  }
  .images {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
  }
  .AP {
      width: 300px;
      height: 300px;
      position: relative;
      margin-bottom: 100px;
      overflow: hidden;
      border: 5px solid #3c97f7;
 
      img {
          position: absolute;
          width: 135%;
      }
  }
  .appel {
      width: 100%;
      height: 75px;
      background-color: #3c97f7;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
          margin-left: 50px;
          color: #fff;
          font-weight: bold;
          font-size: 17px;
      }
      a {
          padding: 10px 40px;
          margin-right: 5%;
          text-decoration: none;
          color: #3c97f7;
          background-color: #fff;
          border-radius: 8px;
          font-weight: bold;
      }
  }
  @media screen and (max-width: 800px) {
      .titre {
          h2 {
              font-size: 20px;
          }
          p {
              font-size: 14px;
          }
      }
      .icon_container .icon p {
          font-size: 1rem;
      }
  }
  @media screen and (max-width: 600px) {
      .titre {
          h2 {
              font-size: 16px;
          }
          p {
              font-size: 13px;
          }
      }
      .icon_container {
          margin-top: 100px;
          margin-bottom: 120px;
      }
      .appel p {
          font-size: 14px;
      }
      .appel a {
          padding: 8px 20px;
          font-size: 14px;
      }
  }
  @media screen and (max-width: 500px) {
      .titre {
          h2 {
              font-size: 16px;
              margin: 50px 60px 0 60px;
          }
          p {
              font-size: 12px;
              margin: 10px 60px 0 60px;
              line-height: 20px;
          }
      }
      .icon_container {
          flex-direction: column;
          margin-top: 70px;
          margin-bottom: 100px;

          .icon {
              width: 60%;
              margin: 30px 0;

              img {
                  width: 40%;
              }
          }
      }
      .appel p {
          margin-left: 20px;
          font-size: 14px;
      }
      .appel a {
          padding: 8px 20px;
          font-size: 14px;
      }
  }
</style>
